<template>
    <div class="houseinfobox1">
        <diV class="house_info_1_price">798</diV>
        <diV class="house_info_1_text">万</diV>
        <div class="house_info_1_box">
            <div class="box_content_1">15205.0元/㎡</div>
            <div class="box_content_2">首付及税费详请咨询经纪人</div>
        </div>
    </div>
</template>

<script>

export default{
    setup(){

        return{
            
        }
    }
}
</script>

<style scoped>
.houseinfobox1{
    height: 8vh;
    width: 36vw;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: rgba(0,0,0,0.2);
}
.houseinfobox1 .house_info_1_price{
    float: left;
    color: red;
    font-size: 36px;
}
.houseinfobox1 .house_info_1_text{
    float: left;
    color: red;
    font-size: 24px;
    margin-left: 10px;
    margin-top: 10px;
}
.houseinfobox1 .house_info_1_box{
    float: left;
    width: 20vw;
    height: 100%;
    margin-left: 30px;
}
.houseinfobox1 .box_content_1{
    font-size: 15px;
}
.houseinfobox1 .box_content_2{
    font-size: 15px;
    margin-top: 4px;
}

</style>