<template>
    <div class="post"  @click="postdetail">
        <!--房屋图片展示区-->
        <div class="home_image">
            <img src="../assets/images/house1.png">
        </div>

        <!--房屋信息区-->
        <div class="home_info">
            <div class="home_info_desc">绿地国际</div>
            <div class="home_info_address">南通-2256560</div>
            <div class="home_info_detail">楼层：3/3，房龄：1年</div>
            <div class="home_info_publicdate">发布时间：2024年6月3日</div>
        </div>

        <!--面积、价格区-->
        <div class="home_prize">
            <div class="home_prize_num">798</div>
            <div class="home_prize_text">万</div>
        </div>
        <div class="home_rooms">
            <div class="home_rooms_1">四室</div>
            <div class="home_rooms_2">（3厅3卫）</div>
        </div>
        <div class="home_size">
            <div class="home_size_num">171</div>
            <div class="home_size_text">平方</div>
        </div>

    </div>
</template>

<script>
import { useRouter } from "vue-router";
export default{
    //props:['id','author','category','comment_count','favorite_count','post_text','fmt_publish_time', 'is_favorite',"watch_count"],  //这里id就是post_id
    setup(){
        const router = useRouter();
        
        const postdetail = () => {
            const url = router.resolve({
                name:'postdetail_index',
                query:{
                    id:"1",
                }
            })
            window.open(url.href)
        }
 

        return{
            postdetail,
        }
    }
}
</script>

<style scoped>
.post{
    width: 68vw;
    height: 34vh;
    position: relative;
    box-sizing: border-box;/*加padding或者margin后盒子会被撑大，加上这句盒子就不会被撑大了*/
    padding-left: 10px;
    padding-top: 10px;
    border-top-style: solid;
    border-top-width: 0.5px;
    border-top-color: rgba(0,0,0,0.2);
    cursor: pointer;  /*鼠标悬停变小手*/
}
.post .home_image{
    float: left;
}
.post .home_image img{
    display: block;
    margin-top: 1vh;
    width: 10vw;
    height: 30vh;
}

.post .home_info{
    float: left;
    margin-left: 3vw;
}
.post .home_info .home_info_desc{
    font-size: 26px;
    color: blue;
    margin-top: 3vh;
}
.post .home_info .home_info_address{
    font-size: 14px;
    margin-top: 4vh;
}
.post .home_info .home_info_detail{
    font-size: 14px;
    margin-top: 4vh;
}
.post .home_info .home_info_publicdate{
    font-size: 14px;
    margin-top: 4vh;
}

.post .home_prize{
    float: left;
    margin-left: 6vw;
    margin-top: 13vh;
}
.post .home_prize .home_prize_num{
    float: left;
    color: red;
    font-size: 26px;
}
.post .home_prize .home_prize_text{
    float: left;
    font-size: 14px;
    margin-left: 4px;
    margin-top: 8px;
}


.post .home_rooms{
    float: left;
    margin-left: 6vw;
    margin-top: 11.5vh;
}
.post .home_rooms .home_rooms_1{
    font-size: 14px;
    margin-left: 10px;
}
.post .home_rooms .home_rooms_2{
    font-size: 14px;
    margin-top: 20px;
}


.post .home_size{
    float: left;
    margin-left: 6vw;
    margin-top: 13vh;
}
.post .home_size .home_size_num{
    float: left;
    color: red;
    font-size: 26px;
}
.post .home_size .home_size_text{
    float: left;
    font-size: 14px;
    margin-left: 4px;
    margin-top: 8px;
}
</style>