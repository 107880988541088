import { createRouter, createWebHistory } from 'vue-router'

import HomeIndexView from "../views/Home/HomeIndexView"
import PostDetailIndexView from "../views/PostDetail/PostDetailIndexView.vue"
import SendPostIndexView from "../views/SendPost/SendPostIndexView.vue"
import ChangePersonalInfoIndexView from "../views/ChangePersonalInfo/ChangePersonalInfoIndexView.vue"
import PersonalPageIndexView from "../views/PersonalPage/PersonalPageIndexView.vue"
import LoginIndexView from "../views/LoginRegister/LoginIndexView.vue"
import RegisterIndexView from "../views/LoginRegister/RegisterIndexView.vue"


const routes = [
    {
        path: "/",
        name: "home",
        redirect: "/home",
        meta: {
            requestAuth: false,
        }
    },
    {
        path:"/home",
        name:"home_index",
        component:HomeIndexView,
        meta:{
            requestAuth: false,
        }
    },
    {
        path:"/postdetail",
        name:"postdetail_index",
        component:PostDetailIndexView,
        meta:{
            requestAuth: false,
            }
    },
    {
        path:"/sendpost",
        name:"sendpost_index",
        component: SendPostIndexView,
        meta:{
            requestAuth: false,
        }
    },
    {
        path:"/personalpage",
        name:"personalpage_index",
        component: PersonalPageIndexView,
        meta:{
            requestAuth: false,
        } 
    },
    {
        path:"/changepersonalinfo",
        name:"changepersonalinfo_index",
        component: ChangePersonalInfoIndexView,
        meta:{
            requestAuth: false,
        }
    },
    {
        path:"/login",
        name:"login_index",
        component: LoginIndexView,
        meta:{
            requestAuth:false,
        }
    },
    {
        path:"/register",
        name:"register_index",
        component: RegisterIndexView,
        meta:{
            requestAuth:false,
        }
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router