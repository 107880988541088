<template>
    <div class="container">
        <div class="page_mid">
            <div class="house_image" :style="{backgroundImage: 'url(' + `${imgStr}` + ')'}"></div>
            <p class="tip"><span class="error"></span></p>
            <input type="file" name="image" accept="image/*" @change='uploadImg'  class="houseimg-upload-btn" >
            <div class="info_box">
                <div class="box1">
                    <div class="box">
                        <div class="box_text">房产总价：</div>
                        <input v-model="house_price" class="box_input" type="text">
                        <div class="box1_text">万</div>
                    </div>
                    <div class="box">
                        <div class="box_text">房屋面积：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                    <div class="box">
                        <div class="box_text">所属楼层：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                    <div class="box">
                        <div class="box_text">房龄：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                    <div class="box">
                        <div class="box_text">装修状况：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                </div>
                <div class="box2">
                    <div class="box">
                        <div class="box_text">所属小区：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                    <div class="box">
                        <div class="box_text">所属区域：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                    <div class="box">
                        <div class="box_text">停车场状况：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                    <div class="box">
                        <div class="box_text">房屋朝向：</div>
                        <input v-model="house_price" class="box_input" type="text">
                    </div>
                    <div class="box">
                        <div class="box_text">房间数量：</div>
                        <input v-model="house_price" class="box1_input" type="text">
                        <div class="box1_text">室</div>
                        <input v-model="house_price" class="box1_input" type="text">
                        <div class="box1_text">厅</div>
                        <input v-model="house_price" class="box1_input" type="text">
                        <div class="box1_text">卫</div>
                    </div>
                </div>
            </div>
            
            <div class="update-btn" @click="updatePersonalInfo">发布</div>

        </div>
    </div>
</template>

<script>
import {ref} from "vue"
export default{
    components: {

    },
    
    setup(){
        // 上传图片相关变量定义
        let errorStr = ref()
        let buffer = ref()
        let imgStr = ref()

        const uploadImg = (e) => { // 上传本地图片
            let file = e.target.files[0]
            buffer.value = file;
            console.log(file)
            // 获取图片的大小，做大小限制有用
            let imgSize = file.size
            // console.log(imgSize)

            // 比如上传头像限制5M大小，这里获取的大小单位是b
            if (imgSize <= 5 * 1024* 1024) {
                // 图片大小符合要求
                errorStr.value = ""
                // base64方法
                let reader = new FileReader()
                reader.readAsDataURL(file) // 读出 base64
                reader.onloadend = function () {
                    //图片的 base64 格式, 可以直接当成 img 的 src 属性值
                    var dataURL = reader.result
                    //console.log(dataURL)
                    imgStr.value = dataURL
                }
            } else {
                // 图片大小超出限制
                errorStr.value = '图片大小超出范围'
            }
        }

        return{      
            errorStr,
            buffer,
            imgStr,
            uploadImg,
        }
    }
}

</script>
 
<style scoped>
.container{
    width: 100%;
    height: 100%;
    margin-top: 10vh;
    padding-top: 1vh;
    background-color: rgb(242,243,245);
    overflow:hidden; /*父盒子内有的任一级子盒子有浮动会导致父盒子无法高度自适应（即被子盒子撑开）*/
}

.page_mid{
    margin-left: 16vw;
    height: 120vh;
    width: 68vw;
    background-color: white;
    padding-top: 4vh;
}

.page_mid .house_image{
    height: 50vh;
    width: 68vw;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.page_mid .tip{
    height: 6vh;
    width: 58vw;
    margin-left: 19vw;
}
.page_mid .houseimg-upload-btn{
    height: 6vh;
    width: 58vw;
    margin-left: 24vw;
}

.page_mid .info_box{
    height: 36vh;
    width: 68vw;
}
.page_mid .info_box .box1{
    float: left;
    width: 34vw;
}
.page_mid .info_box .box2{
    float: left;
    width: 34vw;
}
.box {
    height: 6vh;
    width: 58vw;
    margin-left: 2vw;
}
.box .box_text{
    float: left;
    width: 9vw;
    font-size: 20px;
}
.box .box_input{
    float: left;
    height: 4vh;
    width: 16vw;
    font-size: 20px;
}
.box .box1_text{
    float: left;
    width: 2vw;
    font-size: 20px;
    margin-left: 10px;
}
.box .box1_input{
    float: left;
    height: 4vh;
    width: 3vw;
    font-size: 20px;
}

.update-btn{
    width: 8vw;
    height: 2.5vw;
    margin-left: 29vw;
    margin-top: 2vh;
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: rgb(30,128,255);
}
.update-btn:hover{
    background-color: rgb(122, 175, 245);
}

</style>