<template>
    <div class="houseinfobox3">
        <div class="box1">有地下停车厂</div>
        <div class="box2">
            <div class="box2_text">所属小区</div>
            <div class="box2_pos">绿地国际</div>
        </div>
        <div class="box3">
            <div class="box3_text">所属区域</div>
            <div class="box3_pos">江苏南通</div>
        </div>
    </div>
</template>

<script>

export default{
    setup(){

        return{
            
        }
    }
}
</script>

<style scoped>
.houseinfobox3{
    height: 14h;
    width: 36vw;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: rgba(0,0,0,0.2);
}
.houseinfobox3 .box1{
    width: 36vw;
    height: 3vh;
    font-size: 13px;
    margin-top: 12px;
}
.houseinfobox3 .box2{
    height: 5.5vh;
    width: 36vw;
    margin-top: 12px;
}
.houseinfobox3 .box2 .box2_text{
    float: left;
    font-size: 15px;
    color: rgba(0,0,0,0.4);
}
.houseinfobox3 .box2 .box2_pos{
    float: left;
    font-size: 15px;
    margin-left: 10px;
}

.houseinfobox3 .box3{
    height: 5.5vh;
    width: 36vw;
}
.houseinfobox3 .box3 .box3_text{
    float: left;
    font-size: 15px;
    color: rgba(0,0,0,0.4);
}
.houseinfobox3 .box3 .box3_pos{
    float: left;
    font-size: 15px;
    margin-left: 10px;
}
</style>