<template>
    <div class="post"  @click="postdetail">
        <div class="house_status">
            <div class="status">显示中</div>
            <div class="status_text2">2024-6-12</div>
            <div class="status_text1">发布时间：</div>
        </div>

        <div class="house_info">
            <div class="house_info_image" :style="{backgroundImage: 'url(' + `${imgStr}` + ')'}"></div>
            <div class="house_info_box">
                <div class="box1">求租/面积为113平方米左右的房屋</div>
                <div class="box2">113㎡ 公寓出租</div>
                <div class="box3">2元/天</div>
            </div>
            <div class="house_info_btn">编辑</div>
            <div class="house_info_btn">删除</div>
            <div class="house_info_btn">查看</div>
        </div>

    </div>
</template>

<script>
import {ref} from "vue"
export default{
    //props:['id','author','category','comment_count','favorite_count','post_text','fmt_publish_time', 'is_favorite',"watch_count"],  //这里id就是post_id
    setup(){
        let imgStr = ref("https://www.csxxw.top/fntongchengapiv1/static/images/defaultavatar.jpg")

        return{
            imgStr
        }
    }
}
</script>

<style scoped>
.post{
    width: 68vw;
    height: 34vh;
    position: relative;
    box-sizing: border-box;/*加padding或者margin后盒子会被撑大，加上这句盒子就不会被撑大了*/
    padding-top: 10px;
    border-top-style: solid;
    border-top-width: 0.5px;
    border-top-color: rgba(0,0,0,0.2);
    cursor: pointer;  /*鼠标悬停变小手*/
}
.post .house_status{
    width: 64vw;
    height: 6vh;
    margin-left: 2vw;
    margin-right: 2vw;
    box-sizing: border-box;/*加padding或者margin后盒子会被撑大，加上这句盒子就不会被撑大了*/
}
.post .house_status .status{
    float: left;
    width: 10vw;
    font-size: 26px;
    color: rgb(104, 224, 184);
}
.post .house_status .status_text1{
    float: right;
    width: 6vw;
    font-size: 13px;
    margin-top: 10px;
}
.post .house_status .status_text2{
    float: right;
    width: 10vw;
    font-size: 13px;
    margin-top: 10px;
}

.post .house_info{
    width: 68vw;
    height: 20vh;
}
.post .house_info .house_info_image{
    float: left;
    width: 18vw;
    height: 20vh;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
.post .house_info .house_info_box{
    float: left;
    width: 20vw;
    height: 20vh;
}
.post .house_info .house_info_box .box1{
    width: 20vw;
    height: 10vh;
}
.post .house_info .house_info_box .box2{
    width: 20vw;
    height: 6vh;
    font-size: 14px;
    color: rgba(0,0,0,0.7);
}
.post .house_info .house_info_box .box3{
    width: 20vw;
    height: 6vh;
    font-size: 14px;
    color: rgba(0,0,0,0.7);
}

.post .house_info .house_info_btn{
    float: left;
    width: 6vw;
    height: 2.5vw;
    font-size: 16px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: rgb(30,128,255);
    margin-left: 2vw;
    margin-top: 10vh;
}
.post .house_info .house_info_btn:hover{
    background-color: rgb(122, 175, 245);
}


</style>