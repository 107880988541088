<template>
    <div class="houseinfobox2">
        <div class="box1">
            <div class="box1_top">
                <div class="box1_top_num">4</div>
                <div class="box1_top_text">室</div>
                <div class="box1_top_num">3</div>
                <div class="box1_top_text">厅</div>
                <div class="box1_top_num">2</div>
                <div class="box1_top_text">卫</div>
            </div>
            <div class="box1_bottom">共三层</div>
        </div>
        <div class="box2">
            <div class="box2_top">
                <div class="box2_top_1">171</div>
                <div class="box2_top_2">㎡</div>
            </div>
            <div class="box2_bottom">精装修</div>
        </div>
        <div class="box3">
            <div class="box3_top">南</div>
            <div class="box3_bottom">别墅</div>
        </div>
    </div>
</template>

<script>

export default{
    setup(){

        return{
            
        }
    }
}
</script>

<style scoped>
.houseinfobox2{
    height: 12vh;
    width: 36vw;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: rgba(0,0,0,0.2);
}
.houseinfobox2 .box1{
    float: left;
    width: 12vw;
}
.houseinfobox2 .box1 .box1_top{
    width: 12vw;
    height: 6vh;
}
.houseinfobox2 .box1 .box1_top .box1_top_num{
    float: left;
    font-size: 28px;
}
.houseinfobox2 .box1 .box1_top .box1_top_text{
    float: left;
    font-size: 18px;
    margin-top: 10px;
}
.houseinfobox2 .box1 .box1_bottom{
    width: 12vw;
    height: 6vh;
    font-size: 18px;
    margin-top: 10px;
}


.houseinfobox2 .box2{
    float: left;
    width: 12vw;
}
.houseinfobox2 .box2 .box2_top{
    height: 6vh;
    width: 12vw;
}
.houseinfobox2 .box2 .box2_top .box2_top_1{
    float: left;
    font-size: 26px;
}
.houseinfobox2 .box2 .box2_top .box2_top_2{
    float: left;
    font-size: 14px;
    margin-top: 10px;
}
.houseinfobox2 .box2 .box2_bottom{
    height: 6vh;
    width: 12vw;
    font-size: 18px;
    margin-top: 10px;
}


.houseinfobox2 .box3{
    float: left;
    width: 12vw;
    min-height: 12vh;
}
.houseinfobox2 .box3 .box3_top{
    font-size: 18px;
    height: 6vh;
    margin-top: 5px;
}
.houseinfobox2 .box3 .box3_bottom{
    font-size: 18px;
    margin-top: 5px;
}
</style>