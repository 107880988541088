<template>
    <div class="container">
        <!--中间部分：帖子的容器-->
        <div class="page_mid">
            <div class="summary">绿地国际</div>
            <div class="post_detail_box">
                <div class="house_image"></div>
                <div class="house_info">
                    <HouseInfoBox1></HouseInfoBox1>
                    <HouseInfoBox2></HouseInfoBox2>
                    <HouseInfoBox3></HouseInfoBox3>
                    <HouseInfoBox4></HouseInfoBox4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HouseInfoBox1 from "../../components/HouseInfoBox1.vue"
import HouseInfoBox2 from "../../components/HouseInfoBox2.vue"
import HouseInfoBox3 from "../../components/HouseInfoBox3.vue"
import HouseInfoBox4 from "../../components/HouseInfoBox4.vue"
export default{
    components: {
        HouseInfoBox1,
        HouseInfoBox2,
        HouseInfoBox3,
        HouseInfoBox4
    },

    setup(){

        return{      
        }
    }
}

</script>
 
<style scoped>
.container{
    width: 100%;
    height: 100%;
    margin-top: 10vh;
    padding-top: 1vh;
    background-color: rgb(242,243,245);
    overflow:hidden; /*父盒子内有的任一级子盒子有浮动会导致父盒子无法高度自适应（即被子盒子撑开）*/
}

.page_mid{
    margin-left: 13vw;
    height: 100vh;
    width: 74vw;
    background-color: white;
}

.page_mid .summary{
    font-size: 36px;
}
.page_mid .post_detail_box{
    margin-top: 3vh;
    height: 100%;
    width: 100%;
}
.page_mid .post_detail_box .house_image{
    float: left;
    width: 38vw;
    height: 50vh;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/house1.png");
}

.page_mid .post_detail_box .house_info{
    float: left;
    height: 100%;
    width: 36vw;
}

</style>