<template>
    <div class="houseinfobox4">
        <div class="box1">
            <img src="../assets/images/avatar.jpg">
        </div>
        <div class="box2">
            <div class="box2_1">
                <div class="name">彭先生</div>
                <div class="identity">业主</div>
            </div>
            <div class="box2_2">已通过实名认证</div>
        </div>
    </div>
</template>

<script>

export default{
    setup(){

        return{
            
        }
    }
}
</script>

<style scoped>
.houseinfobox4{
    height: 12vh;
    width: 36vw;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: rgba(0,0,0,0.2);
}
.houseinfobox4 .box1{
    float: left;
    width: 6vw;
    height: 6vw;
}
.houseinfobox4 .box1 img{
    display: block;
    width: 4vw;
    height: 4vw;
    border-radius: 3vw;
    margin-top: 10px;
}

.houseinfobox4 .box2{
    float: left;
    width:20vw;
    height: 12vh;
    margin-left: 10px;
    margin-top: 10px;
}
.houseinfobox4 .box2 .box2_1{
    height: 6vh;
    width:20vw;
}
.houseinfobox4 .box2 .box2_1 .name{
    float: left;
    font-size: 24px;
}
.houseinfobox4 .box2 .box2_1 .identity{
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 10px;
}
.houseinfobox4 .box2 .box2_2{
    height: 6vh;
    width:20vw;
    font-size: 16px;
}


</style>