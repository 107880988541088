<template>
    <div class="container">
        <div class="page_mid">
            <AMy59PostInfo></AMy59PostInfo>
            <AMy59PostInfo></AMy59PostInfo>
            <AMy59PostInfo></AMy59PostInfo>
            <AMy59PostInfo></AMy59PostInfo>
            <AMy59PostInfo></AMy59PostInfo>
        </div>
    </div>
</template>

<script>
import AMy59PostInfo from '../../components/AMy59PostInfo.vue';
export default{
    components: {
        AMy59PostInfo,
    },

    setup(){

        return{      

        }
    }
}

</script>
 
<style scoped>
.container{
    width: 100%;
    height: 100%;
    margin-top: 10vh;
    padding-top: 1vh;
    background-color: rgb(242,243,245);
    overflow:hidden; /*父盒子内有的任一级子盒子有浮动会导致父盒子无法高度自适应（即被子盒子撑开）*/
}

.page_mid{
    margin-left: 16vw;
    height: 100%;
    width: 68vw;
    background-color: white;
}
</style>