import { createStore } from 'vuex'
import ModuleUser from './user'

export default createStore({
    state: {
        backBaseUrl: "https://www.csxxw.top/fntongchengapiv1"
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      user: ModuleUser,
    }
  })